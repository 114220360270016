<label *ngIf="label && label.length > 0">{{label}}</label>
<div *ngIf="(_data | async) as treeViewData" class="fiba-multiselect">

    <custom-dropdown-treeview [config]="config"
                              [items]="treeViewData"
                              [parentInSelection]="parentInSelection"
                              [disabled]="disabled"
                              [(ngModel)]="value"
                              (selectedChange)="onSelectedChange($event)"
                              class="fiba-select">
    </custom-dropdown-treeview>
</div>